<script>
import Vue from 'vue'
import {
  BCard, BTable, BRow, BCol, BFormGroup, BInputGroup, BFormDatepicker, BButton, BFormSelect, BCardHeader, BCardBody, BFormInput, BBadge, BButtonGroup, BPagination, BModal, BForm, BOverlay,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import store from '@/store/index'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import VueSweetalert2 from 'vue-sweetalert2'
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment'
// eslint-disable-next-line no-unused-vars
import { required, email } from '@validations'

Vue.use(VueSweetalert2)

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BTable,
    BRow,
    BCol,
    BFormGroup,
    BInputGroup,
    BButtonGroup,
    BFormInput,
    BFormDatepicker,
    BFormSelect,
    BButton,
    BCardHeader,
    BCardBody,
    BBadge,
    BPagination,
    BModal,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BOverlay,
    vSelect,
  },
  data() {
    return {
      myFilter: (option, label, search) => {
        const temp = search.toLowerCase()
        return option.cRazonSocial.toLowerCase().indexOf(temp) > -1 || option.cNumeroRuc.toLowerCase().indexOf(temp) > -1
      },
      selectedItem:null,
      records: [],
      fields: [
        { key: 'inv_fecha_emision', label: 'Fecha Emisión', sortable: true },
        { key: 'inv_razon_social_cliente', label: 'Cliente', sortable: true },
        { key: 'inv_correlativo', label: 'Número', sortable: true },
        { key: 'inv_estado', label: 'Estado', sortable: true },
        { key: 'inv_moneda', label: 'Moneda', sortable: true },
        {
          key: 'inv_monto_gravado', label: 'T.Gravado', sortable: true, tdClass: 'text-right',
        },
        {
          key: 'inv_monto_igv', label: 'T.Igv', sortable: true, tdClass: 'text-right',
        },
        {
          key: 'inv_monto_total', label: 'Total', sortable: true, tdClass: 'text-right',
        },
        {
          key: '09', label: 'Descargas', sortable: false, tdClass: 'text-center', thClass: 'text-center',
        },
        {
          key: '10', label: 'Acciones', sortable: false, tdClass: 'text-center',
        },
      ],
      data: {
        desde: moment().startOf('month').format('yyyy-MM-DD'),
        hasta: moment().endOf('month').format('yyyy-MM-DD'),
        serie: '',
        numero: '',
        cliente: '',
      },
      status: [{
        G: 'PENDIENTE', A: 'ACEPTADO', B: 'ANULADO', R: 'RECHAZADO', O: 'OBSERVADO', U: 'ERROR AL ENVIAR',
      },
      {
        G: 'light-primary', A: 'light-success', B: 'light-warning', R: 'light-danger', O: 'light-info', U: 'light-danger',
      }],
      series: null,
      see_more: false,
      options: {
        number: {
          numeral: true,
        },
      },
      selectedCliente: null,
      clientes: [],
      inputSearch: '',
      currentPage: 1,
      entries: [10, 20, 50, 100],
      showEntrie: 10,
      totalElements: 0,
      is_filter: false,
      showDialogOptions: false,
      base64: '',
      tittlePdf: '',
      showSendMail: false,
      emailValue: '',
      showLoading: false,
      id: 0,
      sort: 'id',
      order: 'desc',
    }
  },
  mounted() {
    this.getInfoByID()
    // this.showLoading = false
    this.getRecords()
  },
  methods: {
    async getInfoByID() {
      const request = {
        url: '/api/series/',
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }
      const respRoles = await store.dispatch('back/EXECUTE', request)
      this.series = []
      for (let i = 0; i < respRoles.length; i += 1) {
        this.series.push(
          {
            value: respRoles[i].id,
            text: respRoles[i].numero,
          },
        )
      }
    },
    async getRecords() {
      this.showLoading = true
      let url = '/api/invoices/'
      url += `?limit=${this.showEntrie}&page=${this.currentPage}&order=${this.order}&sort=${this.sort}`
      const request = {
        url,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }
      const resp = await store.dispatch('back/EXECUTE', request)
      this.records = resp.rows
      for (let i = 0; i < this.records.length; i += 1) {
        this.records[i].inv_fecha_emision = moment.utc(this.records[i].inv_fecha_emision).format('DD-MM-yyyy')
        this.records[i].inv_correlativo = `${this.records[i].inv_serie}-${this.records[i].inv_correlativo}`
        // this.series.push("text": respRoles.series[i].serie.numero)
      }
      this.totalElements = resp.responseFilter.total_rows
      this.showLoading = false
    },
    async getRecordsbyFilter() {
      this.showLoading = true
      let url = '/api/invoices/reportByFilter/'
      url += `?limit=${this.showEntrie}&page=${this.currentPage}&order=${this.order}&sort=${this.sort}`
      const request = {
        url,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
        data: this.data,
      }
      const resp = await store.dispatch('back/EXECUTE', request)
      this.records = resp.rows
      for (let i = 0; i < this.records.length; i += 1) {
        this.records[i].inv_fecha_emision = moment.utc(this.records[i].inv_fecha_emision).format('DD-MM-yyyy')
        this.records[i].inv_correlativo = `${this.records[i].inv_serie}-${this.records[i].inv_correlativo}`
        // this.series.push("text": respRoles.series[i].serie.numero)
      }
      this.totalElements = resp.responseFilter.total_rows
      this.showLoading = false
    },
    filter() {
      this.currentPage = 1
      this.is_filter = true
      this.getRecordsbyFilter()
    },
    clean() {
      this.is_filter = false
      this.currentPage = 1
      this.data.desde = moment().startOf('month').format('yyyy-MM-DD')
      this.data.hasta = moment().endOf('month').format('yyyy-MM-DD')
      this.data.serie = ''
      this.data.numero = ''
      this.data.cliente = ''
      this.selectedCliente = null
      this.getRecords()
    },
    clickSeeMore() {
      this.see_more = !this.see_more
    },
    changeValue(e) {
      if (e) {
        this.data.cliente = e.cNumeroRuc
      } else {
        this.data.cliente = ''
      }
    },
    async onSearch(search) {
      this.inputSearch = search
      this.clientes = []
    },
    busquedaChange() {
      if (this.inputSearch.length >= 3) {
        this.getClientes(this.inputSearch)
      }
    },
    async getClientes(e) {
      const request = {
        url: `/api/clients/byFilter/${e}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }
      const resp = await store.dispatch('back/EXECUTE', request)
      this.clientes = resp
    },
    convertedBlobtoBase64(blob) {
      // eslint-disable-next-line no-new
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onerror = reject
        reader.onload = () => {
          resolve(reader.result)
        }
        reader.readAsDataURL(blob)
      })
    },
    async downloadPDF(item) {
      const request = {
        url: `/api/invoices/downloadPDF/${item.inv_name_file_pdf}`,
        method: 'GET',
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }
      const resp = await store.dispatch('back/EXECUTE', request)
      const url = window.URL.createObjectURL(new Blob([resp]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', item.inv_name_file_pdf)
      document.body.appendChild(link)
      link.click()
    },
    async showPDF(item) {
      const request = {
        url: `/api/invoices/downloadPDF/${item.inv_name_file_pdf}`,
        method: 'GET',
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }
      this.selectedItem = item
      const resp = await store.dispatch('back/EXECUTE', request)
      const base64 = await this.convertedBlobtoBase64(resp)
      const base64result = base64.substr(base64.indexOf(',') + 1)
      this.showDialogOptions = true
      this.base64 = base64result
      this.tittlePdf = item.inv_name_file_pdf
    },
    async downloadXML(item) {
      const request = {
        url: `/api/invoices/downloadXML/${item.inv_name_file_xml}`,
        method: 'GET',
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }
      const resp = await store.dispatch('back/EXECUTE', request)
      const url = window.URL.createObjectURL(new Blob([resp]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', item.inv_name_file_xml)
      document.body.appendChild(link)
      link.click()
    },
    async downloadCDR(item) {
      const request = {
        url: `/api/invoices/downloadCDR/${item.inv_name_file_cdr}`,
        method: 'GET',
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }
      const resp = await store.dispatch('back/EXECUTE', request)
      const url = window.URL.createObjectURL(new Blob([resp]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', item.inv_name_file_cdr)
      document.body.appendChild(link)
      link.click()
    },
    async anular(item) {
      this.$swal({
        title: 'Está seguro?',
        text: 'El documento será anulado!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí, anular!',
        cancelButtonText: 'No',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.sendanular(item)
        }
      })
    },
    async sendanular(item) {
      const pedidos = {
        arrayId: [],
      }
      pedidos.arrayId = item.inv_pedidos
      this.showLoading = true
      const request = {
        url: `/api/invoices/cancelInvoice/${item.id}`,
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
        data: pedidos,
      }
      const resp = await store.dispatch('back/EXECUTE', request)
      if (resp.success) {
        this.sendMessage(resp.message, 'EditIcon', 'success')
      } else {
        this.sendMessage(resp.message, 'AlertCircleIcon', 'danger')
      }
      this.getData()
      this.showLoading = false
    },
    async sendInvoice(item) {
      this.showLoading = true
      const request = {
        url: `/api/invoices/sendSunat/${item.id}`,
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }
      const resp = await store.dispatch('back/EXECUTE', request)
      if (resp.success) {
        this.sendMessage(resp.message, 'EditIcon', 'success')
      } else {
        this.sendMessage(resp.message, 'AlertCircleIcon', 'danger')
      }
      this.getData()
      this.showLoading = false
    },
    async consultPDF(type){
      this.showDialogOptions = false
      this.showLoading = true
      const request = {
        url: `/api/invoices/consultSUNAT/${this.selectedItem.id}?type=`+type,
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }
      const resp = await store.dispatch('back/EXECUTE', request)
      if (resp.success) {
        this.showPDF(this.selectedItem)
      } else {
        this.sendMessage(resp.message, 'AlertCircleIcon', 'danger')
      }
      this.showLoading = false
    },
    async consultarSunat(item) {
      this.showLoading = true
      const request = {
        url: `/api/invoices/consultSUNAT/${item.id}`,
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }
      const resp = await store.dispatch('back/EXECUTE', request)
      if (resp.success) {
        this.sendMessage(resp.message, 'EditIcon', 'success')
      } else {
        this.sendMessage(resp.message, 'AlertCircleIcon', 'danger')
      }
      this.getData()
      this.showLoading = false
    },
    sendMessage(title, icon, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          variant,
        },
      })
    },
    cambioPagina(e) {
      this.currentPage = e
      this.getData()
    },
    changeSizePage() {
      this.getData()
    },
    getData() {
      if (this.is_filter) {
        this.getRecordsbyFilter()
      } else {
        this.getRecords()
      }
    },
    doSometing() {
      this.showDialogOptions = false
    },
    sendMail(item) {
      this.showSendMail = true
      this.id = item.id
    },
    hideModal() {
      this.showSendMail = false
      this.emailValue = ''
      this.id = 0
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.sendEmail()
        }
      })
    },
    async sendEmail() {
      const senddata = {
        id: this.id,
        mail: this.emailValue,
      }
      this.showLoading = true
      const request = {
        url: '/api/invoices/sendMail',
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
        data: senddata,
      }
      const resp = await store.dispatch('back/EXECUTE', request)
      if (resp.success) {
        this.sendMessage('Correo Enviado', 'EditIcon', 'success')
      } else {
        this.sendMessage('Error', 'AlertCircleIcon', 'danger')
      }
      this.getData()
      this.showLoading = false
    },
    sortChanged(data) {
      this.sort = data.sortBy
      if (data.sortDesc) {
        this.order = 'desc'
      } else this.order = 'asc'
      if (this.filter) {
        this.getRecordsbyFilter()
      } else {
        this.getRecords()
      }
    },
    // eslint-disable-next-line consistent-return
    isNumberInt(evt) {
      // eslint-disable-next-line no-param-reassign
      evt = (evt) || window.event
      const charCode = (evt.which) ? evt.which : evt.keyCode
      if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
        evt.preventDefault()
      } else {
        return true
      }
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

</style>
<template>
  <div>
    <b-overlay
      variant="white"
      :show="showLoading"
      spinner-variant="primary"
      blur="0"
      opacity=".75"
      rounded="sm"
    >
      <b-card no-body>
        <b-card-header>
          <h5>
            Filtros
            <template v-if="!see_more">
              <a
                class="control-label font-weight-bold text-info font-custom"
                @click="clickSeeMore"
              ><strong> [+ Ver más]</strong></a>
            </template>
            <template v-else>
              <a
                class="control-label font-weight-bold text-info font-custom"
                @click="clickSeeMore"
              ><strong> [- Ver menos]</strong></a>
            </template>
          </h5>
        </b-card-header>
        <b-card-body v-if="see_more">
          <b-row>
            <b-col md="3">
              <b-form-group
                label="Fecha del: "
              >
                <b-input-group>
                  <b-form-datepicker
                    id="datepicker-desde"
                    v-model="data.desde"
                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                    :max="data.hasta"
                    locale="es"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                label="Fecha al: "
              >
                <b-input-group>
                  <b-form-datepicker
                    id="datepicker-hasta"
                    v-model="data.hasta"
                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                    :min="data.desde"
                    locale="es"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                label="Serie: "
              >
                <b-form-select
                  v-model="data.serie"
                  class="form-control"
                >
                  <option
                    v-for="option in series"
                    :key="option.value"
                  >
                    {{ option.text }}
                  </option>
                </b-form-select>
                <!-- <b-form-input
                  v-model="data.serie"
                  placeholder="Serie"
                /> -->
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                label="Número: "
              >
                <b-form-input
                  v-model="data.numero"
                  type="number"
                  placeholder="Ingresar"
                  @keypress="isNumberInt($event)"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Cliente: "
              >
                <b-input-group>
                  <v-select
                    v-model="selectedCliente"
                    label="cRazonSocial"
                    style="width: 100%;"
                    :options="clientes"
                    :filter-by="myFilter"
                    @input="changeValue($event)"
                    @keyup.native.enter="busquedaChange($event)"
                    @search="onSearch"
                  >
                    <span slot="no-options">Sin resultados.</span>
                    <template>
                      <b-spinner label="Loading..." />
                    </template>
                    <template v-slot:selected-option="option">
                      {{ option.cNumeroRuc }} - {{ option.cRazonSocial }}
                    </template>
                    <template v-slot:option="option">
                      {{ option.cNumeroRuc }} - {{ option.cRazonSocial }}
                    </template>
                  </v-select>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col
              md="6"
              class="mt-2"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-1"
                @click.prevent="filter"
              >
                <feather-icon
                  icon="SearchIcon"
                  class="mr-50"
                />
                <span class="align-middle">Buscar</span>
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="secondary"
                class="mr-1"
                @click.prevent="clean"
              >
                <feather-icon
                  icon="TrashIcon"
                  class="mr-50"
                />
                <span class="align-middle">Limpiar</span>
              </b-button>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
      <b-card>
        <b-table
          :sticky-header="false"
          :hover="true"
          responsive
          :fields="fields"
          :items="records"
          @sort-changed="sortChanged"
        >
          <template #cell(inv_estado)="data">
            <b-badge :variant="status[1][data.value]">
              {{ status[0][data.value] }}
            </b-badge>
          </template>
          <template #cell(inv_monto_gravado)="data">
            {{ data.item.inv_monto_gravado - data.item.inv_monto_gravado_deduccion  }}
          </template>
          <template #cell(09)="data">
            <b-button-group vertical>
              <b-button
                size="sm"
                variant="danger"
                @click="downloadPDF(data.item)"
              >
                PDF
              </b-button>
              <b-button
                size="sm"
                class="mt-1"
                variant="warning"
                @click="downloadXML(data.item)"
              >
                XML
              </b-button>
              <b-button
                v-if="data.item.inv_estado !== 'G' && data.item.inv_estado !== 'U' && data.item.inv_estado !== 'O'"
                size="sm"
                class="mt-1"
                variant="warning"
                @click="downloadCDR(data.item)"
              >
                CDR
              </b-button>
            </b-button-group>
          </template>
          <template #cell(10)="data">
            <b-button-group vertical>
              <b-button
                v-if="data.item.inv_estado === 'G' || data.item.inv_estado === 'U' || data.item.inv_estado === 'O'"
                size="sm"
                variant="success"
                @click="sendInvoice(data.item)"
              >
                ENVIAR
              </b-button>
              <b-button
                v-if="data.item.inv_estado === 'A'"
                size="sm"
                class="mt-1"
                variant="danger"
                @click="anular(data.item)"
              >
                ANULAR
              </b-button>
              <b-button
                v-if="data.item.inv_estado === 'A'"
                size="sm"
                class="mt-1"
                variant="warning"
                @click="sendMail(data.item)"
              >
                CORREO
              </b-button>
              <b-button
                v-if="data.item.inv_estado === 'A'"
                size="sm"
                class="mt-1"
                variant="warning"
                @click="consultarSunat(data.item)"
              >
                CONSULTAR
              </b-button>
              <b-button
                size="sm"
                class="mt-1"
                variant="info"
                @click="showPDF(data.item)"
              >
                VER
              </b-button>
            </b-button-group>
          </template>
        </b-table>
        <b-row>
          <b-col sm="3">
            <b-form-group
              label-cols="4"
              label-cols-md="4"
              label-size="md"
              label="Entradas:"
              label-for="input-md"
            >
              <b-form-select
                v-model="showEntrie"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :options="entries"
                @change="changeSizePage"
              />
            </b-form-group>
          </b-col>
          <b-col
            sm="3"
            class="mt-75"
            style="font-size:12px"
          >
            <span> {{ totalElements }} Registros en total</span>
          </b-col>
          <b-col
            sm="6"
            style="text-align: right;"
          >
            <b-pagination
              v-model="currentPage"
              :per-page="showEntrie"
              :total-rows="totalElements"
              :align="'right'"
              @change="cambioPagina"
            />
          </b-col>
        </b-row>
      </b-card>
    </b-overlay>
    <b-modal
      ref="my-modal"
      v-model="showDialogOptions"
      size="lg"
      modal-class="modal-secondary"
      centered
      hide-footer
      :title="tittlePdf"
      @hide="doSometing"
    >
      <div class="text-center mb-1 ">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="warning"
          class="mr-1"
          @click.prevent="consultPDF(1)"
        >
          <span class="align-middle">Ticket</span>
        </b-button>

        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="danger"
          class="mr-1"
          @click.prevent="consultPDF(2)"
        >
          <span class="align-middle">A4</span>
        </b-button>        
        </div>
      <object
        style="width:100%; height:842pt"
        type="application/pdf"
        :data="`data:application/pdf;base64,${[base64]}`"
      />
    </b-modal>
    <b-modal
      ref="my-modal2"
      v-model="showSendMail"
      size="lg"
      modal-class="modal-secondary"
      centered
      hide-footer
      @hide="hideModal"
    >
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <b-col md="10">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    v-model="emailValue"
                    :state="errors.length > 0 ? false:null"
                    type="email"
                    placeholder="Email"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="2">
              <b-button
                variant="primary"
                type="submit"
                @click.prevent="validationForm"
              >
                ENVIAR
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>
